<!-- 商超统计 -->

<template>
  <div class="supermarketStatistics">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="supermarketValue" placeholder="请搜索商超名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <!-- <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelect" clearable>
        </el-date-picker> -->
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="shopName" label="商超名称"
            width="180"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="brand" label="品牌" width="180"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="productName" label="商品名称"
            width="180"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="barCode" label="商品条形码"
            width="180"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="factoryName" label="厂家"
            width="180"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="purchaseAmount" label="收货数量"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="sellAmount" label="销售数量"></el-table-column>
          <el-table-column min-width="140" show-overflow-tooltip prop="inventoryAmount" label="库存数量"></el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      supermarketValue: '', // 搜索商超名称
      productValue: '', // 搜索商品名称
      barCode: '',
      // dataValue: '', // 搜索时间区间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getSupermarketStatistics();
  },
  methods: {
    // 获取列表
    getSupermarketStatistics() {
      this.loading = true;
      let params = {
        supermarketName: this.supermarketValue || null, // 搜索商超名称
        productName: this.productValue || null, // 搜索商品名称
        barCode: this.barCode || null,
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间
      // if (this.dataValue && this.dataValue.length === 2) {
      //   params.startTime = this.dataValue[0];
      //   params.endTime = this.dataValue[1];
      // } else {
      //   params.startTime = null;
      //   params.endTime = null;
      // }

      this.$axios.get(this.$api.getSupermarketStatistics, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getSupermarketStatistics();
    },
    // // 时间搜索
    // handleSelect(value) {
    //   this.clearData();
    //   this.dataValue = value;
    //   this.getSupermarketStatistics();
    // },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getSupermarketStatistics();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getSupermarketStatistics();
    },
  }

}



</script>

<style scoped lang="scss">
.supermarketStatistics {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
